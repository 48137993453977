import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const ServidoresDedicados = () => {
  const [prices, setPrices] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  
  const plans = [
    {
      id: 'ryzen5500',
      name: 'AMD Ryzen 5 5500',
      logo: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fs1.best-wallpaper.net%2Fwallpaper%2Fm%2F1811%2FAMD-Ryzen-Processor-logo_m.jpg&f=1&nofb=1&ipt=46a9ce82788e0aeeb39a6e9a5198175fce96afa7fb0dfa28544ae2c785a38d94&ipo=images',
      cpu: 'AMD Ryzen 5 5500 [@4.2GHz]',
      cores: '6 cores / 12 threads',
      ram: [
        { name: '32GB DDR4 3200Mhz', price: 0 },
        { name: '64GB DDR4 3200Mhz', price: 100 }
      ],
      storage: [
        { name: '1x SSD 512GB NVMe', price: 0 },
        { name: '1x SSD 1TB NVMe', price: 100 }
      ],
      network: [
        { name: '1Gbps', price: 0 },
        { name: '10Gbps', price: 500 }
      ],
      basePrice: 599.99,
      configLink: 'https://discord.gg/3T3tc5ehrX'
    },
    {
      id: 'intel12400f',
      name: 'Intel Core i5 12400F',
      logo: 'https://imgs.search.brave.com/oU92qqo73821guPIpZtzx_5dmvb5SGxs5PDJgaTtYCY/rs:fit:32:32:1:0/g:ce/aHR0cDovL2Zhdmlj/b25zLnNlYXJjaC5i/cmF2ZS5jb20vaWNv/bnMvOTRlNDQ2ZDg1/YjFkMDkyNzc0NmM5/MGUzODMyNTM4Zjg3/ZTYxZWI0NDAwYTNm/MWYxOGFhY2RjOWNh/NmQ4ZWY5My93d3cu/aW50ZWwuY29tLw',
      cpu: 'Intel Core i5 12400F [@4.4GHz]',
      cores: '6 cores / 12 threads',
      ram: [
        { name: '32GB DDR4 3200Mhz', price: 0 },
        { name: '64GB DDR4 3200Mhz', price: 100 }
      ],
      storage: [
        { name: '1x SSD 512GB NVMe', price: 0 },
        { name: '1x SSD 1TB NVMe', price: 100 }
      ],
      network: [
        { name: '1Gbps', price: 0 },
        { name: '10Gbps', price: 500 }
      ],
      basePrice: 649.99,
      configLink: 'https://discord.gg/3T3tc5ehrX'
    },
    {
      id: 'ryzen5900',
      name: 'AMD Ryzen 9 5900X',
      logo: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fs1.best-wallpaper.net%2Fwallpaper%2Fm%2F1811%2FAMD-Ryzen-Processor-logo_m.jpg&f=1&nofb=1&ipt=46a9ce82788e0aeeb39a6e9a5198175fce96afa7fb0dfa28544ae2c785a38d94&ipo=images',
      cpu: 'AMD Ryzen 9 5900x [@4.9GHz]',
      cores: '12 cores / 24 threads',
      ram: [
        { name: '128GB DDR4 3200Mhz', price: 0 },
      ],
      storage: [
        { name: '1x SSD 1TB NVMe', price: 0 },
        { name: '1x SSD 2TB NVMe', price: 100 }
      ],
      network: [
        { name: '1Gbps', price: 0 },
        { name: '10Gbps', price: 500 }
      ],
      basePrice: 1099.99,
      configLink: 'https://discord.gg/3T3tc5ehrX'
    },
    {
      id: 'ryzen5950',
      name: 'AMD Ryzen 9 5950X',
      logo: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fs1.best-wallpaper.net%2Fwallpaper%2Fm%2F1811%2FAMD-Ryzen-Processor-logo_m.jpg&f=1&nofb=1&ipt=46a9ce82788e0aeeb39a6e9a5198175fce96afa7fb0dfa28544ae2c785a38d94&ipo=images',
      cpu: 'AMD Ryzen 9 5950x [@4.9GHz]',
      cores: '16 cores / 32 threads',
      ram: [
        { name: '128GB DDR4 3200Mhz', price: 0 }
      ],
      storage: [
        { name: '1x SSD 1TB NVMe', price: 0 },
        { name: '1x SSD 2TB NVMe', price: 100 }
      ],
      network: [
        { name: '1Gbps', price: 0 },
        { name: '10Gbps', price: 500 }
      ],
      basePrice: 1299.99,
      configLink: 'https://discord.gg/3T3tc5ehrX'
    },
    {
      id: 'ryzen7900',
      name: 'AMD Ryzen 9 7900',
      logo: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fs1.best-wallpaper.net%2Fwallpaper%2Fm%2F1811%2FAMD-Ryzen-Processor-logo_m.jpg&f=1&nofb=1&ipt=46a9ce82788e0aeeb39a6e9a5198175fce96afa7fb0dfa28544ae2c785a38d94&ipo=images',
      cpu: 'AMD Ryzen 9 7900 [@5.4GHz]',
      cores: '12 cores / 24 threads',
      ram: [
        { name: '128GB DDR5 4800Mhz', price: 0 },
      ],
      storage: [
        { name: '1x SSD 1TB NVMe', price: 0 },
        { name: '1x SSD 2TB NVMe', price: 100 }
      ],
      network: [
        { name: '1Gbps', price: 0 },
        { name: '10Gbps', price: 500 }
      ],
      basePrice: 1499.99,
      configLink: 'https://discord.gg/3T3tc5ehrX'
    },
    {
      id: 'ryzen7950',
      name: 'AMD Ryzen 9 7950X',
      logo: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fs1.best-wallpaper.net%2Fwallpaper%2Fm%2F1811%2FAMD-Ryzen-Processor-logo_m.jpg&f=1&nofb=1&ipt=46a9ce82788e0aeeb39a6e9a5198175fce96afa7fb0dfa28544ae2c785a38d94&ipo=images',
      cpu: 'AMD Ryzen 9 7950x [@5.6GHz]',
      cores: '16 cores / 32 threads',
      ram: [
        { name: '128GB DDR5 4800Mhz', price: 0 },
        { name: '192GB DDR5 4800Mhz', price: 1000 }
      ],
      storage: [
        { name: '1x SSD 2TB NVMe', price: 0 },
        { name: '1x SSD 4TB NVMe', price: 500 }
      ],
      network: [
        { name: '1Gbps', price: 0 },
        { name: '10Gbps', price: 500 }
      ],
      basePrice: 1799.99,
      configLink: 'https://discord.gg/3T3tc5ehrX'
    }
  ];

  useEffect(() => {
    const initialPrices = {};
    const initialOptions = {};
    plans.forEach(plan => {
      initialPrices[plan.id] = plan.basePrice;
      initialOptions[plan.id] = {
        ram: plan.ram[0].name,
        storage: plan.storage[0].name,
        network: plan.network[0].name
      };
    });
    setPrices(initialPrices);
    setSelectedOptions(initialOptions);

    const customSelects = document.getElementsByClassName("custom-select");
    Array.from(customSelects).forEach(initializeCustomSelect);

    document.addEventListener("click", closeAllSelect);

    return () => {
      document.removeEventListener("click", closeAllSelect);
    };
  }, []);

  const closeAllSelect = (elmnt) => {
    const selectItems = document.getElementsByClassName("select-items");
    const selectSelected = document.getElementsByClassName("select-selected");
    const arrNo = [];

    Array.from(selectSelected).forEach((item, index) => {
      if (elmnt === item) {
        arrNo.push(index);
      } else {
        item.classList.remove("select-arrow-active");
      }
    });

    Array.from(selectItems).forEach((item, index) => {
      if (arrNo.indexOf(index)) {
        item.classList.add("select-hide");
      }
    });
  };

  const initializeCustomSelect = (select) => {
    const selectElement = select.getElementsByTagName("select")[0];
    const selectedDiv = document.createElement("DIV");
    selectedDiv.setAttribute("class", "select-selected");
    selectedDiv.innerHTML = selectElement.options[selectElement.selectedIndex].innerHTML;
    select.appendChild(selectedDiv);

    const optionList = document.createElement("DIV");
    optionList.setAttribute("class", "select-items select-hide");

    Array.from(selectElement.options).forEach((option) => {
      const optionDiv = document.createElement("DIV");
      optionDiv.innerHTML = option.innerHTML;
      optionDiv.addEventListener("click", function (e) {
        // Removida a chamada para updateSelectUI
        // Adicionada lógica para atualizar a seleção
        const y = this.parentNode.getElementsByClassName("same-as-selected");
        Array.from(y).forEach(item => item.removeAttribute("class"));
        this.setAttribute("class", "same-as-selected");
        selectedDiv.innerHTML = this.innerHTML;
        selectElement.value = this.innerHTML;
        selectedDiv.click();
        // Dispara o evento change manualmente
        const event = new Event('change', { bubbles: true });
        selectElement.dispatchEvent(event);
      });
      optionList.appendChild(optionDiv);
    });

    select.appendChild(optionList);
    selectedDiv.addEventListener("click", function (e) {
      e.stopPropagation();
      closeAllSelect(this);
      optionList.classList.toggle("select-hide");
      this.classList.toggle("select-arrow-active");
    });
  };

  const updatePrice = (serverId, category, e) => {
    const plan = plans.find(p => p.id === serverId);
    const selectedValue = e.target.value;

    setSelectedOptions(prevOptions => {
      const newOptions = {
        ...prevOptions,
        [serverId]: {
          ...prevOptions[serverId],
          [category]: selectedValue
        }
      };

      const newPrice = calculatePrice(plan, newOptions[serverId]);

      setPrices(prevPrices => ({
        ...prevPrices,
        [serverId]: newPrice
      }));

      return newOptions;
    });
  };

  const calculatePrice = (plan, options) => {
    let totalPrice = plan.basePrice;
    ['ram', 'storage', 'network'].forEach(category => {
      const selectedOption = plan[category].find(option => option.name === options[category]);
      if (selectedOption) {
        totalPrice += selectedOption.price;
      }
    });
    return totalPrice;
  };

  const serverStyle = {
    display: 'flex',
    width: '100%',
    maxWidth: '1000px',
    backgroundColor: '#fdfdfd',
    borderRadius: '10px',
    padding: '20px',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    marginBottom: '20px'
  };

  const serverInfoStyle = {
    flex: 1
  };

  const serverHeaderStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px'
  };

  const logoStyle = {
    width: '50px',
    height: '50px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '50%',
    marginRight: '15px'
  };

  const specStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: '20px'
  };

  const specItemStyle = {
    flex: '1 0 30%',
    marginBottom: '10px'
  };

  const labelStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px'
  };

  const iconStyle = {
    marginRight: '5px',
    color: '#7a0cf3'
  };

  const priceStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px'
  };

  const buttonStyle = {
    backgroundColor: '#7a0cf3',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer'
  };

  return (
    <section style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '40px', margin: '0', padding: '20px', marginTop: '180px' }}>
      <div className="-mx-4 flex flex-wrap" data-id="ad1d9fc2-aa91-4e61-a" style={{marginTop: 50}}>
        <div className="w-full px-4 selected" data-id="5d819f80-c039-4377-a">
          <div
            className="mx-auto mb-[60px] max-w-[510px] text-center"
            data-id="5780492e-9c37-4fc3-8"
          >
            <span
              className="mb-2 block text-lg font-semibold text-primary"
              content-id="0d5991b5-e73b-459e-9"
              data-id="5d3621cf-4eb1-45a2-b"
            >
              Escolha o seu dedicado com os melhores!
            </span>
            <h2
              className="mb-3 text-3xl leading-[1.208] font-bold textopreto sm:text-4xl md:text-[40px]"
              content-id="fbe17dee-4bb0-4f20-9"
              data-id="4f4da58c-adec-4698-b"
            >
              Alugue dedicados conosco!
            </h2>
            <p
              className="text-base text-body-color dark:text-dark-6"
              content-id="dd613355-b4f8-47fa-a"
              data-id="165ba753-a88a-4fd7-b"
            >
              Hospede sua aplicação em uma das melhores infraestruturas do país!
            </p>
          </div>
        </div>
      </div>

      {plans.map(plan => (
        <div key={plan.id} style={serverStyle}>
          <div style={serverInfoStyle}>
            <div style={serverHeaderStyle}>
              <div style={{ ...logoStyle, backgroundImage: `url(${plan.logo})` }}></div>
              <h2 style={{ fontSize: '24px', fontWeight: 600 }}>{plan.name}</h2>
            </div>
            <div style={specStyle}>
              <div style={specItemStyle}>
                <label style={labelStyle}>
                  <i className="fas fa-microchip" style={iconStyle}></i>
                  CPU
                </label>
                <p style={{ marginTop: '5px' }}>{plan.cpu}</p>
              </div>
              <div style={specItemStyle}>
                <label style={labelStyle}>
                  <i className="fas fa-cogs" style={iconStyle}></i>
                  Cores
                </label>
                <p style={{ marginTop: '5px' }}>{plan.cores}</p>
              </div>
              <div style={specItemStyle}>
                <label style={labelStyle}>
                  <i className="fas fa-memory" style={iconStyle}></i>
                  RAM
                </label>
                <div className="custom-select">
                  <select value={selectedOptions[plan.id]?.ram} onChange={(e) => updatePrice(plan.id, 'ram', e)}>
                    {plan.ram.map((option, index) => (
                      <option key={index} value={option.name}>
                        {option.name} {option.price > 0 ? `(+R$ ${option.price.toFixed(2)})` : ''}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div style={specItemStyle}>
                <label style={labelStyle}>
                  <i className="fas fa-hdd" style={iconStyle}></i>
                  Armazenamento
                </label>
                <div className="custom-select">
                  <select value={selectedOptions[plan.id]?.storage} onChange={(e) => updatePrice(plan.id, 'storage', e)}>
                    {plan.storage.map((option, index) => (
                      <option key={index} value={option.name}>
                        {option.name} {option.price > 0 ? `(+R$ ${option.price.toFixed(2)})` : ''}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div style={specItemStyle}>
                <label style={labelStyle}>
                  <i className="fas fa-network-wired" style={iconStyle}></i>
                  Rede
                </label>
                <div className="custom-select">
                  <select value={selectedOptions[plan.id]?.network} onChange={(e) => updatePrice(plan.id, 'network', e)}>
                    {plan.network.map((option, index) => (
                      <option key={index} value={option.name}>
                        {option.name} {option.price > 0 ? `(+R$ ${option.price.toFixed(2)})` : ''}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div style={specItemStyle}>
                <label style={labelStyle}>
                  <i className="fas fa-desktop" style={iconStyle}></i>
                  Placa de Vídeo
                </label>
                <div style={{ width: '100%', padding: '8px', borderRadius: '5px', backgroundColor: '#f1f1f1', color: '#c1c1c1b0', cursor: 'not-allowed' }}>
                  NÃO DISPONÍVEL
                </div>
              </div>
            </div>
            <div style={priceStyle}>
              <p style={{ fontSize: '18px', fontWeight: 500 }}>A partir de R$ <span style={{ fontWeight: 700 }}>{prices[plan.id]?.toFixed(2)}</span></p>
              <Link to={plan.configLink} style={buttonStyle}>Configurar</Link>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default ServidoresDedicados;